import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { SolutionsHeader } from "~/constants/data";
import { Menus } from "~/constants/menus";
import { Template, TemplateApi, TemplateStatus } from "~/services/template";
import Trans from "~/types/translationKeys";
import { getQueryParams } from "~/utils/common";
import Button from "../Button";
import Icon from "../Icon";
import { IconNames } from "../Icon/Icon.type";
import MenuItem from "../MenuItem";
import styles from "./Header.module.scss";
import HeaderDrawer from "./components/HeaderDrawer";
import DiscordBanner from "../HeaderBanner/DiscordBanner";
import { isTurnOffLTD } from "../../utils/time";

interface HeaderProps {
  isShowBanner?: boolean;
  isShowMenu?: boolean;
  RightComponent?: ReactNode;
}

const Header: FC<HeaderProps> = ({
  isShowBanner = true,
  isShowMenu = true,
  RightComponent,
}) => {
  const router = useRouter();
  const { utm_campaign, utm_source } = router.query;
  const [isActive, setIsActive] = useState(false);
  const [cookie, _] = useCookies(["utm_source", "utm_blog", "others"]);
  const isBlog = router.asPath.includes("resources/b");
  const isAIStudio = router.asPath.includes("ai_studio");
  const [isScrolled, setIsScrolled] = useState(false);
  const { t } = useTranslation();
  const isDarkMode = isAIStudio && !isScrolled;
  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(() => {
    TemplateApi.getList({
      page: 1,
      limit: 10,
      status: TemplateStatus.PUBLISHED,
    })
      .then((res) => {
        setTemplates(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window !== "undefined") {
        setIsScrolled(window.scrollY > 0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const isHideLTD = useMemo(() => isTurnOffLTD(), []);

  return (
    <div className={styles.header_container}>
      {isShowBanner && <DiscordBanner />}
      <header
        className={classNames(styles.header, {
          [styles.dark_mode]: isDarkMode,
        })}
      >
        <div
          className={classNames(
            styles.header_content_container,
            "flex items-center justify-between relative z-[15] gap-4 md:gap-10"
          )}
        >
          <div className="flex items-center gap-3">
            {isShowMenu && (
              <div
                className={classNames(styles.hamburger, {
                  [styles.active]: isActive,
                })}
                onClick={() => {
                  setIsActive(!isActive);
                }}
              >
                <div className={styles.line} />
                <div className={styles.line} />
                <div className={styles.line} />
              </div>
            )}
            <Link href={`/#`} className={styles.header_logo}>
              <Image
                src={isDarkMode ? "/svgs/logo_white.svg" : "/svgs/logo.svg"}
                alt="CX Genie logo"
                fill
              />
            </Link>
          </div>

          {isShowMenu && (
            <nav className={classNames(styles.nav, "hidden lg:flex")}>
              <ul className={styles.menu_list}>
                <MenuItem
                  isDarkMode={isDarkMode}
                  text={t(Trans.solutions)}
                  submenuClassName={styles.solutions}
                  renderSubmenu={(closeSubmenu) => (
                    <div className="grid grid-cols-2">
                      {SolutionsHeader.map((solution, index) => (
                        <Link
                          key={solution.title}
                          href={solution.url}
                          onClick={closeSubmenu}
                        >
                          <div
                            className={classNames(
                              "h-full",
                              styles.submenu_item,
                              styles[`item_${index + 1}`]
                            )}
                          >
                            <div className={styles.icon}>
                              <Icon
                                name={solution.icon as IconNames}
                                color="white"
                                size={17}
                              />
                            </div>
                            <div className="flex-1">
                              <h3 className={styles.submenu_item_title}>
                                {t(solution.title)}
                              </h3>
                              <p className={styles.submenu_item_desc}>
                                {t(solution.desc)}
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}
                />
                <Fragment>
                  {Menus.map((menuItem) => (
                    <MenuItem
                      isDarkMode={isDarkMode}
                      key={menuItem.key}
                      text={t(menuItem.text)}
                      submenu={menuItem.submenu}
                      url={menuItem.url}
                    />
                  ))}
                  {/* {isHideLTD ? null : (
                    <Link
                      href={"https://appsumo.8odi.net/Wqkk7X"}
                      aria-label={"Lifetime Deal"}
                      className={styles.gradient}
                      target="_blank"
                    >
                      <li>{t(Trans.lifetime_deal)}</li>
                    </Link>
                  )} */}
                </Fragment>
              </ul>
            </nav>
          )}

          {!RightComponent ? (
            <Fragment>
              {" "}
              <div className="items-center hidden gap-3 md:flex">
                {/* <DropdownLanguage isDarkMode={isDarkMode} /> */}
                <Link
                  type="outline"
                  href={{
                    pathname: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/sign-in`,
                    query: {
                      utm_source: isBlog
                        ? `${cookie["utm_source"] || "blog"}`
                        : `${cookie["utm_source"] || utm_source || ""}`,
                      utm_campaign,
                      utm_blog: isBlog ? cookie["utm_blog"] : "",
                      others: cookie["others"] || getQueryParams(router.asPath),
                    },
                  }}
                  target="_blank"
                >
                  <Button variant="primary" type="borderless">
                    {t(Trans.sign_in)}
                  </Button>
                </Link>
                <Button
                  className="hidden px-3 md:inline-block"
                  type="outline"
                  variant={isDarkMode ? "white" : "secondary"}
                  onClick={() => {
                    (window as any)?.demo_conversion?.();
                    window.open(
                      "https://calendar.app.google/9YB8GKe3ZgNt1z3z7",
                      "_blank"
                    );
                  }}
                >
                  {t(Trans.book_demo)}
                </Button>
                <Link
                  type="outline"
                  href={{
                    pathname: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/sign-up`,
                    query: {
                      utm_source: isBlog
                        ? `${cookie["utm_source"] || "blog"}`
                        : `${cookie["utm_source"] || utm_source || ""}`,
                      utm_campaign,
                      utm_blog: isBlog ? cookie["utm_blog"] : "",
                      others: cookie["others"] || getQueryParams(router.asPath),
                    },
                  }}
                  target="_blank"
                >
                  <Button className="hidden px-3 md:inline-block">
                    {t(Trans.start_for_free)}
                  </Button>
                </Link>
              </div>
              <div className="flex gap-2 md:hidden">
                {/* <DropdownLanguage isMobile isDarkMode={isDarkMode} /> */}

                <Link
                  href={{
                    pathname: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/sign-in`,
                    query: {
                      utm_source: isBlog
                        ? `${cookie["utm_source"] || "blog"}`
                        : `${cookie["utm_source"] || utm_source || ""}`,
                      utm_campaign,
                      utm_blog: isBlog ? cookie["utm_blog"] : "",
                      others: cookie["others"] || getQueryParams(router.asPath),
                    },
                  }}
                  target="_blank"
                >
                  <Button type="outline">{t(Trans.sign_in)}</Button>
                </Link>
              </div>
            </Fragment>
          ) : (
            RightComponent
          )}
        </div>
        {isShowMenu && (
          <HeaderDrawer
            open={isActive}
            handleClose={() => setIsActive(false)}
            templates={templates}
          />
        )}
      </header>
    </div>
  );
};

export default Header;
